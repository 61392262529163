// This will be one of the themed pre-selected groups within the carousel above the fold as the user enters the sphere
import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { philosophers } from '../../PhilosophersData';
import { ChosenPhilosophersContext } from '../../context/ChosenPhilosophersContext';
import ConfirmationModal from '../ConfirmationModal';
import MemoizedPhilosopherImage from './MemoizedPhilosopherImages';


//Import pictures
import SorenKirkegaard from '../../images/marbleStatuePictures/FrontFacing/SorenKierkegaardWhiteBG.jpg';
import AlbertCamus from '../../images/marbleStatuePictures/FrontFacing/AlbertCamusWhiteBG.jpg';
import JeanPaulSatre from '../../images/marbleStatuePictures/FrontFacing/JeanPaulSartreWhiteBG.jpg';
import SimoneWeil from '../../images/marbleStatuePictures/FrontFacing/SimoneWeilWhiteBG.jpg';
import White_chat_bubble from '../../images/icons/White_chat_bubble.svg';

//Import videos
import SorenKirkegaardVideo from '../../videoes/SorenKierkegaard.mp4';
import AlbertCamusVideo from '../../videoes/AlbertCamus.mp4';
import JeanPaulSatreVideo from '../../videoes/JeanPaulSatre.mp4';
import SimoneWeilVideo from '../../videoes/SimoneWeil.mp4';



const FindingMeaningInLife = ({ setIsQuestionsOpen, setIsPhilosopherCatalogVisible, setCurrentUserQuestion, setSpeakerSequence, setIsPhilosophersResponding, setCurrentSpeakerIndex, currentThemeIndex, setAutoPlayOnPause, setPreMadeQuestions }) => {
  const { setSelectedPhilosophers, selectedCount} = useContext(ChosenPhilosophersContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  
  // States for playing the videoes by change of CSS style from image to video in the case that the videos have been fully loaded and is ready to play seemlessly
  const [isSorenKirkegaardVideoReady, setSorenKirkegaardVideoReady] = useState(false);
  const [isAlbertCamusVideoReady, setAlbertCamusVideoReady] = useState(false);
  const [isJeanPaulSatreVideoReady, setJeanPaulSatreVideoReady] = useState(false);
  const [isSimoneWeilVideoReady, setSimoneWeilVideoReady] = useState(false);
  const [isPageVisible, setPageVisible] = useState(true);

  // Refs to access videos elements as means to play them from the start when a user go's to a new theme in the theme carousel
  const sorenVideoRef = useRef(null);
  const albertVideoRef = useRef(null);
  const jeanPaulVideoRef = useRef(null);
  const simoneVideoRef = useRef(null);

  // Variable for the modal to show if one wants to chat with these instead of what was allready in the basket
  const name = 'Søren Kierkegaard, Albert Camus, Jean-Paul Sartre and Simone Weil'
  
  // Variable that initiates the philosophers on the topic of the conversation
  const prompt = "Please, give the visitor of PhiloSphere a personal greeting fitting for your character. Then introduce the theme of finding meaning in life and how you have worked with that as a topic and tell a story about how your lived your own life in accordance with your philosophy. Then end by asking what questions related to the topic they are pondering or if they are looking for advice. Answer in English until you've been told otherwise";

  // Variable for the questions that should be selectable when this themed chat is chosen
  const themeQuestions = [
    "Everyone, what do you believe is the greatest obstacle preventing people from finding true meaning in life?",
    "What advice would you offer to someone struggling to find meaning in a life filled with monotony and routine?",
    "How does the pursuit of happiness intersect with the search for meaning, and are they one and the same?",
    "Is it possible to live a meaningful life without ever questioning what that meaning might be?",
    "Everyone, what role does faith and religion play in finding meaning in life?",
    "Is the pursuit of individual freedom compatible with the search for meaning, or do they conflict in fundamental ways?",
    "In what ways does community and belonging influence our sense of meaning, and can we find meaning in solitude?",
    "Can a life of service to others provide more meaning than a life focused on self-discovery?",
    "Does the pursuit of meaning require suffering, or can it be found in moments of grace and joy?",
    "Is it possible to find meaning in a life full of uncertainty and chaos, or does meaning require a sense of order and structure?",
    "What role does love play in finding meaning, and is love essential to a meaningful life?",
    "Is meaning something that we create through our choices, or is it something we must discover through experiences and reflection?",
    "Does the meaning of life change as we age, or is it constant?",
    "How does our relationship with death shape our understanding of meaning in life?",
    "Is meaning something that is universal, or is it deeply personal and different for each individual?",
    "Does material success contribute to a meaningful life, or does it distract us from finding deeper purpose?",
    "Can art, music, or creativity serve as a path to finding meaning, or are they merely expressions of something already discovered?",
    "Can meaning be found in moments of failure and loss, or is it only present in times of achievement and success?",
    "Camus, if rebellion against the absurd is a source of meaning, can we ever truly overcome our existential despair?",
    // ...other questions
];


  const themedGroup = useMemo(() => [
    philosophers.find(p => p.name === 'Søren Kierkegaard'),
    philosophers.find(p => p.name === 'Albert Camus'),
    philosophers.find(p => p.name === 'Jean-Paul Sartre'),
    philosophers.find(p => p.name === 'Simone Weil')
  ], [])

  const handleStartConverseClick = () => {
    if (selectedCount > 0) {
      setIsConfirmationModalOpen(true);
      setAutoPlayOnPause(true)
    } else {
      setPreMadeQuestions(themeQuestions)
      setIsPhilosophersResponding(true);
      setCurrentSpeakerIndex(0);
      setSelectedPhilosophers(themedGroup)
      setIsPhilosopherCatalogVisible(false)
      setCurrentUserQuestion(prompt);

      // Ensure selectedPhilosophers is updated before setting the sequence
      setTimeout(() => {
        const updatedSelectedPhilosophers = themedGroup;
        setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
      }, 100);
      setIsQuestionsOpen(true)
    }
  }

  const handleConfirm = () => {
    setPreMadeQuestions(themeQuestions)
    setIsPhilosophersResponding(true);
    setCurrentSpeakerIndex(0);
    setSelectedPhilosophers(themedGroup)
    setIsPhilosopherCatalogVisible(false)
    setCurrentUserQuestion(prompt);

    // Ensure selectedPhilosophers is updated before setting the sequence
    setTimeout(() => {
      const updatedSelectedPhilosophers = themedGroup;
      setSpeakerSequence(updatedSelectedPhilosophers.map((_, index) => index + 1));
    }, 100);
    setAutoPlayOnPause(false)
    setIsQuestionsOpen(true)
    setIsConfirmationModalOpen(false)
  }

  const handleCancel = () => {
    setAutoPlayOnPause(false)
    setIsConfirmationModalOpen(false)
  }

  // Function to pause videos when page is not visible
  const pauseVideos = () => {
    if (sorenVideoRef.current) sorenVideoRef.current.pause();
    if (albertVideoRef.current) albertVideoRef.current.pause();
    if (jeanPaulVideoRef.current) jeanPaulVideoRef.current.pause();
    if (simoneVideoRef.current) simoneVideoRef.current.pause();
  };

  // Function to play videos from the start when they are ready
  const playVideosFromStart = () => {
    if (isSorenKirkegaardVideoReady && sorenVideoRef.current) {
      sorenVideoRef.current.currentTime = 0;
      sorenVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isAlbertCamusVideoReady && albertVideoRef.current) {
      albertVideoRef.current.currentTime = 0;
      albertVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isJeanPaulSatreVideoReady && jeanPaulVideoRef.current) {
      jeanPaulVideoRef.current.currentTime = 0;
      jeanPaulVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
    if (isJeanPaulSatreVideoReady && simoneVideoRef.current) {
      simoneVideoRef.current.currentTime = 0;
      simoneVideoRef.current.play().catch(error => console.log('Play request was interrupted:', error));
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPageVisible(false);
        pauseVideos();
      } else {
        setPageVisible(true);
        playVideosFromStart();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isSorenKirkegaardVideoReady, isAlbertCamusVideoReady, isJeanPaulSatreVideoReady, isJeanPaulSatreVideoReady]);

  useEffect(() => {
    if (currentThemeIndex === 1 && isPageVisible) {
      playVideosFromStart();
    }
  }, [currentThemeIndex, isPageVisible, isSorenKirkegaardVideoReady, isAlbertCamusVideoReady, isJeanPaulSatreVideoReady, isJeanPaulSatreVideoReady]);


  
  return (
    <div  className='Carousel-theme'>
      <h2>Finding Meaning in Life</h2>
      <div className='Philosophers-in-theme'>
        <div className='Group1'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={SorenKirkegaard} alt="Søren Kirkegaard" style={{ display: isSorenKirkegaardVideoReady ? 'none' : 'block' }}/>
            <video
              ref={sorenVideoRef}
              src={SorenKirkegaardVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setSorenKirkegaardVideoReady(true);
              }}
              style={{ display: isSorenKirkegaardVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Søren Kirkegaard</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={AlbertCamus} alt="Albert Camus" style={{ display: isAlbertCamusVideoReady ? 'none' : 'block' }}/>
            <video
              ref={albertVideoRef}
              src={AlbertCamusVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setAlbertCamusVideoReady(true);
              }}
              style={{ display: isAlbertCamusVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Albert Camus</h3>
          </div>
        </div>
        <div className='Group2'>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={JeanPaulSatre} alt="Jean-Paul Sartre" style={{ display: isJeanPaulSatreVideoReady ? 'none' : 'block' }}/>
            <video
              ref={jeanPaulVideoRef}
              src={JeanPaulSatreVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setJeanPaulSatreVideoReady(true);
              }}
              style={{ display: isJeanPaulSatreVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Jean-Paul Sartre</h3>
          </div>
          <div className="Philosopher">
            <MemoizedPhilosopherImage src={SimoneWeil} alt="Simone Weil" style={{ display: isSimoneWeilVideoReady ? 'none' : 'block' }}/>
            <video
              ref={simoneVideoRef}
              src={SimoneWeilVideo}
              muted
              playsInline
              onCanPlayThrough={() => {
                setSimoneWeilVideoReady(true);
              }}
              style={{ display: isSimoneWeilVideoReady ? 'block' : 'none'}}
              className='Philosopher-intro-video'
            />
            <h3>Simone Weil</h3>
          </div>
        </div>
      </div>
      <p className='Theme-description' id='Theme-description' >Explore these profound thinker's reflections on existence, meaning, and purpose. Let their wisdom help you navigate the complexities of life and uncover your path.</p>
      <button className='Start-conversation-button' onClick={handleStartConverseClick}> <img src={White_chat_bubble} /> Start Conversation </button>
      <ConfirmationModal 
        isOpen={isConfirmationModalOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        philosopherName={name}
      />
    </div>
  );
};

export default FindingMeaningInLife;